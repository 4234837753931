body {
  overflow-x: hidden;
}

.login-page {
    display: flex;
    justify-content: center;
    height: calc(100vh - var(--header-height));
    background-color: #f0f2f5;
  }
  
  .login-container {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    position: absolute;
    transform: translateY(25%);
  }
  
  .login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .login-header h2 {
    margin: 0;
  }
  
  .register-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="password"] {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .remember-me-form-row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .remember-me-form-row label {
    margin: 0;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    margin-bottom: 1rem;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  button[type="submit"] {
    padding: 0.5rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .login-message {
    margin-bottom: 1rem;
    color: red;
    font-weight: bold;
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #007bff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 10px auto;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
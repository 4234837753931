.App {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; Ensure the app container fills the viewport height */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.record-row {
  font-size: 12px;
}

.canvas-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically, if desired */
  height: 100%; /* Ensure it hays a height, or adjust as needed */
  margin-top: 50px;
}

#my-fabric-canvas {
  border: 1px solid #000;
  font-family: "Cabin", sans-serif;
}

.footer {
  height: 50px; /* Adjust the height as needed */
  width: 100%;
  background-color: #f5f5f5; /* Example background color */
}

.menu-icon {
  font-size: 2rem;
  cursor: pointer;
}

.navbar {
  position: relative; /* Add this if not already present */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  flex: 0 1 auto;
  padding-top: .2rem;
}

.nav-links {
  position: absolute;
  top: 0; /* Adjusted from 100% to 0 */
  right: 0;
  width: 4vw; /* Adjust width as needed */
  background-color: #282c34ea; /* Ensure visibility against the background */
  transition: transform 0.25s ease-in-out;
  transform: translateY(-160%); /* Hide above the viewport */
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 18px;
  padding: 2px;
  
}


.nav-links.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(10%); /* Slide down into view */
  border-radius: 18px;
  padding: 2px;
  min-width: 20px;
  width: 7vw;
  border-style: groove;
  border-width: 2px;
  border-color: #f0f8ff3b;
  box-shadow: 0 2px 4px rgba(41, 37, 151, 0.45);
  z-index: 1000;
}

.nav-links li {
  padding-bottom: 16px;
  list-style: none;
  text-align: center;
}

.nav-links a {
  color: white;
  text-decoration: none;
  position: relative; /* Required for the pseudo-element */
  transition: color 0.3s ease; /* Smooth color transition */
  font-size: 1rem;
}

.nav-links a:hover {
  color: rgb(196, 228, 255);
  text-decoration: none;
}

.nav-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: rgb(196, 228, 255);
  transition: width 0.4s ease;
  -webkit-transition: width 0.3s ease;
}

.nav-links a:hover::after {
  width: 100%;
  left: 0;
  background: rgb(196, 228, 255);
}

@media (max-width: 768px) {
  .nav-links {
    flex-flow: column nowrap;
    position: fixed; /* Keep fixed to the viewport */
    height: 100vh;
    padding-top: 3.5rem;
    transform: translateY(-150%); /* Ensure it's fully hidden */
  }

  .nav-links.active {
    transform: translateY(25%); /* Adjust for desired translation */
  }
}

.company-name {
  color: white;
  font-size: 2rem; /* Adjust based on your preference */
  flex: 1; /* Allows it to grow and take up any available space */
  text-align: center;
}

.company-link {
  color: rgb(255, 255, 255);
  font-size: 2rem; /* Adjust based on your preference */
  flex: 1; /* Allows it to grow and take up any available space */
  text-align: center;
  text-decoration: none;
  background: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255)); /* Start with white gradient */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Make the text color transparent to show the gradient */
  transition: background 1s ease, color 1s ease; /* Slower background transition */
  margin-left: 7vw;
}

.company-link:hover {
  background: linear-gradient(to right, rgb(212, 235, 255), rgb(207, 208, 255), rgb(212, 235, 255)); /* Three-color gradient */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Ensure text color remains transparent */
}


.logo {
  width: 3vw; /* Adjust based on your logo's size */
  height: auto;
  flex: 0 1 auto;
  margin: 4px;
  min-width: 50px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #282c34; /* Match the App-header background */
}

.wms-body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Use min-height to ensure it covers at least the viewport height */
  margin: 0; /* Remove default margin */
  flex-direction: column;
}

.homePage-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh; /* Use min-height to ensure it covers at least the viewport height */
  margin: 0; /* Remove default margin */
  flex-direction: column;
}

.aboutPage-body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Use min-height to ensure it covers at least the viewport height */
  margin: 0; /* Remove default margin */
}


.aboutPage-header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Use min-height to ensure it covers at least the viewport height */
  margin: 0; /* Remove default margin */
  flex-direction: column;
  width: 45vw;
}

.aboutPage-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Use min-height to ensure it covers at least the viewport height */
  margin: 0; /* Remove default margin */
  flex-direction: column;
  width: 45vw;
}

.homePage-body-header {
  text-align: center;
  width: 100%; /* Ensures the header spans the full width */
  min-height: 10vh;
}

.homePage-body-aboutUs {
  width: 48vw;
  margin-left:6vw;
  margin-right: 4vw;
}

.homePage-body-services {
  width: 48vw;
  margin-left:4vw;
  margin-right: 6vw;
}

.homePage-body-footer {
  width: 100%;
  padding-right: 20px; /* Adds some space inside the footer */
  padding-left: 20px;
  padding-top: 20px;
  background-color: #2C3E50; /* A dark background color for contrast */
  color: #FFFFFF; /* White text color for readability */
  text-align: center; /* Centers the text inside the footer */
  font-size: 16px; /* Adjusts the font size for better readability */
  border-top: 2px solid #34495E; /* Adds a subtle border top for visual separation */
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Adds a slight shadow for depth */
}

.sections-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* This will vertically center the content */
  width: 100%; /* Adjust based on your layout needs */
  height: 100%; /* Adjust based on your layout needs */
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  overflow: hidden;
  width: 100%; /* Adjust based on your layout */
  height: 300px; /* Adjust based on your desired height */
  background-color: #121d27;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

.slideshow-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: left 0.5s ease-in-out;
  height: 300px; /* Ensure images have the same height */
  width: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure the image does not overflow the container */
}

.login-button {
  margin-right: 6vw;
  color: rgb(255, 255, 255);
  background-color: #121d2700;
  border-radius: 4px;
  border-color: #00000000;
  font-size: 1.3rem;
  font-family: "Cabin", sans-serif;
  padding-top: 5px;
  padding-right: 12px;
  padding-bottom: 5px;
  padding-left: 12px;
  transition: all 0.2s ease; /* Smooth transition for all properties */
}

.login-button:hover {
  color: rgb(196, 228, 255);
  background: #424b5eb4; /* Lighter background on hover */
  border-color: #282c3425; /* Change border color on hover */
  box-shadow: 0 0 15px rgba(175, 195, 238, 0.8); /* Glowing effect */
  border-radius: 8px;
  transform: scale(1.05); /* Slightly increase the size */
}


.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-modal {
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 18vw; /* Adjust the width as needed */
  min-width: 330px; /* Maximum width */
  height: 60vh; /* Adjust height as needed, auto adapts to content */
  min-height: 60%;
  max-height: 90%; /* Maximum height */
  border-style: groove;
  border-width: 3px;
  border-color: #0706063d;
}

.login-modal form {
  display: flex;
  flex-direction: column;
  padding:20px;
}

.login-modal input,
.login-modal button {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.login-modal button[type="submit"] {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.login-modal button:hover {
  opacity: 0.8;
}

#login-register {
  margin-right: 26px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-color: #00000075;
  border-style: solid;
  border-radius: 60px;
  border-width: 2px;
  background: #fff;
  transition: border-radius 0.2s ease; /* Add this line */
  font-family: "Cabin", sans-serif;
}

#login-register:hover {
  background: #e0e0e0; /* Lighter background on hover */
  border-color: #007bff; /* Change border color on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  border-radius: 14px;
}


#login-login {
  margin-right: 0px;
  margin-left: 26px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Cabin", sans-serif;
}

.cabin-login-pw-text {
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

#close-button {
  position: absolute;
  top: 10px; /* Adjust the value as needed */
  right: -55px; /* Adjust the value as needed */
  padding: 10px;
  border-radius: 100%;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.575);
  border-width: 0.5px;
  width: 44px;
  height: 44px;
  background: #2525259f;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
  transition: 
    background 0.4s ease,
    border 0.4s ease;
}

#close-button:hover {
  border-style: revert;
  border-width: 1px;
  border-color: #007bff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: #eff5ff;
  color: #000000c4;
}

.visualizer-body {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 60vh;
}

.results-table {
  width: 30vw;
  height: 70vh;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto; /* Add vertical scrollbar when content exceeds height */
  border-style: groove;
  border-width: 2px;
  border-color: #f0f8ff3b;
  box-shadow: rgba(41, 37, 151, 0.4) 0px 0px 0px 2px, rgba(41, 37, 151, 0.65) 0px 4px 6px -1px, rgba(41, 37, 151, 0.08) 0px 1px 0px inset;
  border-radius: 16px;
}

#visualizer-table-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  font-family: "Cabin", sans-serif;
}

.visualizer-table-body {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 2px; /* Add spacing between rows */
}

.visualizer-table-header {
  padding: 0px;
}

.visualizer-table-cell {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: 14px;
}

.visualizer-table-cell-header {
  text-align: center;
}

/* Add alternating background colors to rows */
.visualizer-table-body tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.visualizer-table-body tr:nth-child(even) {
  background-color: #ffffff;
}

/* Add alternating background colors to the Object ID column */
.visualizer-table-body tr:nth-child(odd) .object-id-cell {
  background-color: #e6e6e6 !important;
}

.visualizer-table-body tr:nth-child(even) .object-id-cell {
  background-color: #cccccc !important;
}

.register-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form {
  margin-top: 20px;
}

.register-form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px; /* Adjust the value as needed */
}

.required-label::after {
  content: " *";
  color: red;
}

.remember-me-form-row {
  display: flex;
  margin-bottom: 10px; /* Adjust the value as needed */
  margin-top: 10px; /* Adjust the value as needed */
  justify-content: space-between;
}

.register-submit-row {
  display: flex;
  flex-direction: column;
  margin-top: 25px; /* Adjust the value as needed */
}

.register-modal {
  background-color: white;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 18vw; /* Adjust the width as needed */
  min-width: 330px; /* Maximum width */
  min-height: 70%;
  max-height: 90%; /* Maximum height */
  border-style: groove;
  border-width: 3px;
  border-color: #0706063d;
}

.register-modal form {
  display: flex;
  flex-direction: column;
  padding:20px;
}

.register-modal input,
.register-modal button {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.register-modal button[type="submit"] {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.register-modal button:hover {
  opacity: 0.8;
}

#register-close-button {
  position: absolute;
  top: 10px; /* Adjust the value as needed */
  right: -55px; /* Adjust the value as needed */
  padding: 10px;
  border-radius: 100%;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.575);
  border-width: 0.5px;
  width: 44px;
  height: 44px;
  background: #2525259f;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
  transition: 
    background 0.4s ease,
    border 0.4s ease;
}

#register-close-button:hover {
  border-style: revert;
  border-width: 1px;
  border-color: #007bff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background: #eff5ff;
  color: #000000c4;
}

.register-container {
  position: relative;
}

.login-container {
  position: relative;
}

.db-body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Use min-height to ensure it covers at least the viewport height */
  margin: 0; /* Remove default margin */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

.visualizer-wrap {
  display: flex;
  align-content: center;
  justify-content: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  flex-direction: column;
}

.nav-login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5vw;
}

.nav-login-button {
  color: white;
  background-color: #007bff;
  border-radius: 4px;
  border-color: #00000000;
  font-size: 1.3rem;
  font-family: "Cabin", sans-serif;
  padding-top: 5px;
  padding-right: 12px;
  padding-bottom: 5px;
  padding-left: 12px;
  transition: all 0.2s ease; /* Smooth transition for all properties */
  margin-bottom: 0px;
  position: relative; /* Required for pseudo-element positioning */
  overflow: hidden; /* Hide overflow for shimmer effect */
}

.nav-login-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2));
  transition: all 0.3s ease;
}

.nav-login-button:hover::before {
  left: 100%;
  transition: all 0.6s ease;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.visualizer-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.6em;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.visualizer-content th, .visualizer-content td {
  border: 1px solid #dddddd;
  padding: 8px 12px;
  text-align: left;
}

.visualizer-content th {
  background-color: #f2f2f2;
  color: #333;
}

.visualizer-content tr:nth-child(even) {
  background-color: #f9f9f9;
}

.visualizer-content tr:hover {
  background-color: #f1f1f1;
}

.visualizer-header {
  margin-top: 20px;
}